import {getLocal, saveLocal} from "../../utils/localStorage";

import {
    USER_AUTHED,
    USER_UNAUTHED,
    LOGGING_IN
} from "./types";

const KEY_LOGGED_IN = "loggedIn";
const KEY_CLAIMS = "claims";

const INITIAL_STATE = {
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    claims: getLocal(KEY_CLAIMS, true) || {},
    loggingIn: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_AUTHED:
            saveLocal(KEY_CLAIMS, action.payload, true);
            saveLocal(KEY_LOGGED_IN, true, false);

            return {
                ...state,
                loggedIn: true,
                claims: action.payload,
                loggingIn: false
            };
        case USER_UNAUTHED:
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            return {
                ...state,
                loggedIn: false,
                claims: {}
            };
        case LOGGING_IN:
            return {
                ...state,
                loggingIn: true
            };
        default:
            return state;
    }
}

