import {
    SESSIONS_LOADING,
    SESSIONS_SUCCESS,
    SESSIONS_ERROR,
    SESSION_CREATING,
    SESSION_SUCCESS,
    SESSION_ERROR,
    SESSION_DELETED,
    SESSION_WATCH_UPDATED
} from "./types";


const INITIAL_STATE = {
    sessionsLoading: false,
    creatingSession: false,
    sessionMap: {},
    sessionIds: [],
    watchingSlide: null,
    watchingSessionName: null,
};

export default (state = INITIAL_STATE, action) => {
    let sessionIds = null;
    switch (action.type) {
        case SESSIONS_LOADING:
            return {
                ...state,
                sessionsLoading: true
            };
        case SESSIONS_SUCCESS:
            return {
                ...state,
                sessionsLoading: false,
                sessionMap: action.payload.reduce((ret, session) => {
                    ret[session.id] = session;
                    return ret;
                }, state.sessionMap),
                sessionIds: action.payload.map(session => session.id)
            };
        case SESSIONS_ERROR: {
            return {
                ...state,
                sessionsLoading: false
            }
        }
        case SESSION_CREATING:
            return {
                ...state,
                creatingSession: true
            };
        case SESSION_SUCCESS:
            sessionIds = state.sessionIds;
            if (sessionIds.indexOf(action.payload.id) === -1) {
                sessionIds = [action.payload.id, ...sessionIds];
            }
            return {
                ...state,
                creatingSession: false,
                sessionMap: {
                    ...state.sessionMap,
                    [action.payload.id]: action.payload
                },
                sessionIds
            };
        case SESSION_ERROR:
            return {
                ...state,
                creatingSession: false
            };
        case SESSION_DELETED:
            sessionIds = [...state.sessionIds];
            sessionIds.splice(state.sessionIds.indexOf(action.payload), 1);

            return {
                ...state,
                sessionMap: {
                    ...state.sessionMap,
                    [action.payload]: null
                },
                sessionIds
            };
        case SESSION_WATCH_UPDATED:
            return {
                ...state,
                watchingSlide: action.payload.slideUrl,
                watchingSessionName: action.payload.sessionName
            };
        default:
            return state;
    }
}