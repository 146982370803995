import {useShallowEqualSelector} from "../index";

export const useExportsSelector = () => {
    return useShallowEqualSelector(({ exports: { exporting, exportsLoading, exportsIds } }) => {
        return {
            exporting, exportsIds, exportsLoading
        };
    });
};

export const useExportsDetailsSelector = (exportId) => {
    return useShallowEqualSelector(({ exports: { exportsMap } }) => {
        return exportsMap[exportId];
    });
};