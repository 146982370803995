import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import RemoteSlidesApi from '@devsontap/remote-slides-api';

function initFirebase() {
    let config = {
        apiKey: "AIzaSyCBlM6HlvqObwOnuRqiYJVB_h3t7HEUZcU",
        authDomain: "remote-slides-demo.firebaseapp.com",
        databaseURL: "https://remote-slides-demo.firebaseio.com",
        projectId: "remote-slides-demo",
        storageBucket: "remote-slides-demo.appspot.com",
        messagingSenderId: "717895765343",
        appId: "1:717895765343:web:550df6c04bf6c8ade5baea"
    };
    firebase.initializeApp(config);
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

initFirebase();
const api = new RemoteSlidesApi(firebase.firestore, firebase.functions, firebase.auth, firebase.storage);

api.login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ hd: 'devsontap.com' });
    firebase.auth().signInWithPopup(provider);
};

export default api;