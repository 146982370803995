import React, {useEffect} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import SessionItem from "./item";
import {loadSessions} from "../../redux/sessions/actions";
import {useSessionsSelector} from "../../redux/sessions/selectors";

import './index.css';

const SessionsDemo = () => {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const {sessionsLoading, sessionIds} = useSessionsSelector();

    useEffect(() => {
        dispatch(loadSessions(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    return (
        <div>
            <div className="home-section-header text-align-left">
                Remote Slides Sessions
            </div>
            {sessionsLoading ?
                <CircularProgress size={45} />
                :
                <>
                    {sessionIds && sessionIds.length ?
                        sessionIds.map((sessionId, index) => (
                            <SessionItem key={sessionId} sessionId={sessionId} />
                        ))
                        :
                        <div className="marginTop20">
                            No Sessions.
                        </div>

                    }
                </>

            }
        </div>
    )
};

export default SessionsDemo;