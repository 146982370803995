import {
    SLIDES_AUTHED,
    LOGOUT,
    AUTH_TOKENS_LOADING,
    AUTH_TOKENS_ERROR
} from "./types";

/**
 * Loads all the GoogleSlidesExports objects currently in Firestore
 * @param enqueueSnackbar   notistack helper
 */
export const authenticateSlides = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: AUTH_TOKENS_LOADING });
        return api.getAuthUrl(document.location.origin)
            .then(url => {
                document.location.href = url;
                // dispatch({ type: SLIDES_AUTHED, payload: accessToken });
            })
            .catch(err => {
                dispatch({ type: AUTH_TOKENS_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Logs into Firebase
 */
export const login = () => (
    (dispatch, getState, { api }) => {
        return api.login();
    }
);

export const getSlidesAuthTokens = () => (
    (dispatch, getState, { api }) => {
        dispatch({ type: AUTH_TOKENS_LOADING });
        return api.getDecryptedSlidesAuthTokens()
            .then(tokens => {
                dispatch({ type: SLIDES_AUTHED, payload: tokens });
            })
            .catch(err => {
                dispatch({ type: AUTH_TOKENS_ERROR });
                console.error(err);
            });
    }
);

export const logout = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.signOut()
            .then(accessToken => {
                dispatch({ type: LOGOUT });
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);