import React from 'react';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";

import {login} from "../../redux/auth/actions";

import './index.css';

const Login = () => {
    const dispatch = useDispatch();
    return (
        <div className="flex center">
            <Button variant="contained" color="secondary" onClick={() => dispatch(login())}>
                Login with Devs On Tap
            </Button>
        </div>
    )
};

export default Login;