import React, {useState, useEffect} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {useDispatch} from "react-redux";
import * as firebase from "firebase/app";
import "firebase/auth";

import ExportsDemo from "../exports";
import SessionsDemo from "../sessions";
import WatchDemo from "../watch";
import Login from "../login";
import {logout} from "../../redux/auth/actions";

import './index.css';
import {useSessionsSelector} from "../../redux/sessions/selectors";
import AuthProvider from "../_common/authProvider";

const Home = () => {
    const [user, setUser] = useState(firebase.auth().currentUser);
    const dispatch = useDispatch();
    const {watchingSessionName} = useSessionsSelector();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
    }, []);

    return (
        <Router>
            <div>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters={true}>
                        <div className="home-toolbar-title-container text-align-left flex w100">
                            <div className="home-toolbar-title flex align-items-center flex1">
                                {watchingSessionName ? watchingSessionName : "DoT Slides"}
                            </div>
                            <Switch>
                                <Route path="/watch/:sessionId?" render={() => ""} />
                                <Route render={() =>
                                    <>
                                        {user &&
                                            <div className="marginRight25">
                                                <Button variant="text" color="inherit"
                                                        onClick={() => dispatch(logout())}>
                                                    Sign Out
                                                </Button>
                                            </div>
                                        }
                                    </>
                                }/>
                            </Switch>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="home-content">
                    <Switch>
                        <Route path="/watch/:sessionId?" component={WatchDemo} />
                        <Route render={() =>
                            <AuthProvider unAuthed={<Login />}>
                                <div className="home-demo-row">
                                    <ExportsDemo />
                                </div>
                                <div className="home-demo-row">
                                    <SessionsDemo />
                                </div>
                            </AuthProvider>
                        } />
                    </Switch>
                </div>
            </div>
        </Router>
    )
};

export default Home;