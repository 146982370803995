/*global gapi*/
/*global google*/

import {useState, useEffect} from 'react';

const useGooglePicker = (accessToken, callback) => {
    const [picker, setPicker] = useState(false);

    useEffect(() => {
        if (accessToken) {
            if (!window.onApiLoad) {
                window.onApiLoad = onApiLoad;
                const script = document.createElement("script");
                script.src = `https://apis.google.com/js/api.js?onload=onApiLoad`;
                script.async = true;
                document.body.appendChild(script);
            } else {
                onApiLoad();
            }
        }
    }, [accessToken]);  // eslint-disable-line react-hooks/exhaustive-deps

    const onApiLoad = () => {
        gapi.load('picker', onPickerApiLoad);
    };

    const onPickerApiLoad = () => {
        const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId.PRESENTATIONS)
            .setOAuthToken(accessToken.access_token)
            .setDeveloperKey("AIzaSyCBlM6HlvqObwOnuRqiYJVB_h3t7HEUZcU") // This is the Firebase Api Key
            .setCallback(data => {
                if (data.action === google.picker.Action.PICKED) {
                    callback(data.docs[0].id);
                }
            })
            .build();
        setPicker(picker);
    };

    return picker;
};

export default useGooglePicker;