import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import DeleteIcon from '@material-ui/icons/Delete';
import TVIcon from '@material-ui/icons/Tv';
import CopyIcon from '@material-ui/icons/FileCopy';
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import {deleteSession, setSessionSlideNumber} from "../../../redux/sessions/actions";
import {useSessionDetailsSelector} from "../../../redux/sessions/selectors";

import './index.css';

const SessionItem = ({sessionId}) => {
    const [deleting, setDeleting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const session = useSessionDetailsSelector(sessionId);

    const onDelete = () => {
        setDeleting(true);
        dispatch(deleteSession(sessionId, setDeleting, enqueueSnackbar));
    };

    const onNext = () => {
        dispatch(setSessionSlideNumber(sessionId, session.currentSlide + 1, enqueueSnackbar));
    };

    const onPrevious = () => {
        dispatch(setSessionSlideNumber(sessionId, session.currentSlide - 1, enqueueSnackbar));
    };

    const onWatch = () => {
        window.open(`/watch/${sessionId}`);
    };

    const onCopy = () => {
        navigator.clipboard.writeText(document.location.href + `watch/${sessionId}`)
            .then(() => {
                enqueueSnackbar("Text Copied", {variant: 'success'});
            })
    };

    return (
        <Card className="marginTop20">
            <div className="flex">
                <div className="flex1">
                <CardContent>
                    <div className="flex">
                        <div className="flex1 flex column justify-end">
                            <div className="session-item-name">
                                {session.name}
                            </div>
                            <div className="flex marginBottom10">
                                <div className="flex align-items-center">
                                    <IconButton onClick={onPrevious}>
                                        <PreviousIcon />
                                    </IconButton>
                                </div>
                                <div className="marginLeft20 marginRight20 flex align-items-center">
                                    {session.currentSlide + 1} / {session.imageUrls.length}
                                </div>
                                <div className="flex align-items-center">
                                    <IconButton onClick={onNext}>
                                        <NextIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="flex">
                                {deleting ?
                                    <CircularProgress size={25} />
                                    :
                                    <Button variant="text" color="secondary" onClick={onDelete}>
                                        <DeleteIcon className="marginRight5" /> Delete
                                    </Button>
                                }
                                <div className="marginLeft20">
                                    <Button variant="text" color="secondary" onClick={onWatch}>
                                        <TVIcon className="marginRight5" /> Watch
                                    </Button>
                                </div>
                                <div className="marginLeft20">
                                    <Button variant="text" color="secondary" onClick={onCopy}>
                                        <CopyIcon className="marginRight5" /> Copy Watch Url
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
                </div>
                <CardMedia className="session-item-media" image={session.imageUrls[session.currentSlide]} />

                {/*<div className="marginLeft45">*/}
                {/*    <img src={} className="session-item-preview" alt="preview" />*/}
                {/*</div>*/}
            </div>

        </Card>
    )
};

export default SessionItem;