import {
    GOOGLE_SLIDES_EXPORTS_LOADING,
    GOOGLE_SLIDES_EXPORTS_SUCCESS,
    GOOGLE_SLIDES_EXPORTS_ERROR,
    GOOGLE_SLIDES_EXPORTING,
    GOOGLE_SLIDES_EXPORT_SUCCESS,
    GOOGLE_SLIDES_EXPORT_ERROR,
    GOOGLE_SLIDES_EXPORT_DELETED
} from "./types";


const INITIAL_STATE = {
    exportsLoading: false,
    exporting: false,
    exportsMap: {},
    exportsIds: []
};

export default (state = INITIAL_STATE, action) => {
    let exportsIds = null;
    switch (action.type) {
        case GOOGLE_SLIDES_EXPORTS_LOADING:
            return {
                ...state,
                exportsLoading: true
            };
        case GOOGLE_SLIDES_EXPORTS_SUCCESS:
            return {
                ...state,
                exportsLoading: false,
                exportsMap: action.payload.reduce((ret, slidesExport) => {
                    ret[slidesExport.id] = slidesExport;
                    return ret;
                }, state.exportsMap),
                exportsIds: action.payload.map(slidesExport => slidesExport.id)
            };
        case GOOGLE_SLIDES_EXPORTS_ERROR: {
            return {
                ...state,
                exportsLoading: false
            }
        }
        case GOOGLE_SLIDES_EXPORTING:
            return {
                ...state,
                exporting: true
            };
        case GOOGLE_SLIDES_EXPORT_SUCCESS:
            exportsIds = state.exportsIds;
            if (exportsIds.indexOf(action.payload.id) === -1) {
                exportsIds = [action.payload.id, ...exportsIds];
            }
            return {
                ...state,
                exporting: false,
                exportsMap: {
                    ...state.exportsMap,
                    [action.payload.id]: action.payload
                },
                exportsIds
            };
        case GOOGLE_SLIDES_EXPORT_ERROR:
            return {
                ...state,
                exporting: false
            };
        case GOOGLE_SLIDES_EXPORT_DELETED:
            exportsIds = [...state.exportsIds];
            exportsIds.splice(state.exportsIds.indexOf(action.payload), 1);

            return {
                ...state,
                exportsMap: {
                    ...state.exportsMap,
                    [action.payload]: null
                },
                exportsIds
            };
        default:
            return state;
    }
}