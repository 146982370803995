import {useShallowEqualSelector} from "../index";

export const useSessionsSelector = () => {
    return useShallowEqualSelector(({ sessions: { sessionsLoading, creatingSession, sessionIds, watchingSlide, watchingSessionName } }) => {
        return {
            sessionsLoading, sessionIds, creatingSession, watchingSlide, watchingSessionName
        };
    });
};

export const useSessionDetailsSelector = (sessionId) => {
    return useShallowEqualSelector(({ sessions: { sessionMap } }) => {
        return sessionMap[sessionId];
    });
};