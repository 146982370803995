import React, {useState, useEffect, useRef} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LaunchIcon from '@material-ui/icons/Launch';
import UserIcon from '@material-ui/icons/AccountCircle';
import FileIcon from '@material-ui/icons/Description'
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import GoogleSlidesExportItem from "./item";
import RemoteSlidesDialog from "../_common/dialog";
import {loadGoogleSlidesExports, exportGoogleSlidesFile, exportPdf} from "../../redux/exports/actions";
import {useExportsSelector} from "../../redux/exports/selectors";
import {useAuthSelector} from "../../redux/auth/selectors";
import {authenticateSlides, getSlidesAuthTokens} from "../../redux/auth/actions";
import useGooglePicker from "../../hooks/useGooglePicker";

import './index.css';

const ExportsDemo = () => {
    const [exportFileId, setExportFileId] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const {exporting, exportsLoading, exportsIds} = useExportsSelector();
    const {accessToken, tokensLoading} = useAuthSelector();
    const inputRef = useRef(null);
    const picker = useGooglePicker(accessToken, fileId => {
        dispatch(exportGoogleSlidesFile(fileId, accessToken, enqueueSnackbar));
    });

    useEffect(() => {
        dispatch(loadGoogleSlidesExports(enqueueSnackbar));
        dispatch(getSlidesAuthTokens());
    }, [dispatch, enqueueSnackbar]);

    const onAuthenticate = () => {
        dispatch(authenticateSlides(enqueueSnackbar));
    };

    const onExport = () => {
        if (picker) {
            picker.setVisible(true);
        } else {
            enqueueSnackbar("picker not loaded", {variant: 'error'});
        }
    };

    return (
        <div>
            <div className="flex">
                <div className="home-section-header text-align-left">
                    Google Slides Exports
                </div>

                <div className="marginLeft30">
                    {exporting || tokensLoading ?
                        <CircularProgress size={35} />
                        :
                        <>
                            {accessToken ?
                                <Button variant="contained" color="secondary" onClick={onExport}>
                                    <LaunchIcon className="marginRight5" />Export Google Slides File
                                </Button>
                                :
                                <Button variant="contained" color="secondary" onClick={onAuthenticate}>
                                    <UserIcon className="marginRight5" />Authenticate Google Slides
                                </Button>
                            }
                        </>
                    }
                </div>
                <div className="marginLeft15">
                    {!exporting &&
                        <>
                            <Button variant="contained" color="secondary" onClick={() => inputRef.current.click()}>
                                <FileIcon className="marginRight5" />Export PDF
                            </Button>
                            <input type="file" style={{display: "none"}} ref={inputRef} accept=".pdf" onChange={event => {
                                dispatch(exportPdf(event.target.files[0], enqueueSnackbar));
                            }}/>
                        </>
                    }
                </div>
            </div>
            {exportsLoading ?
                <CircularProgress size={45} />
                :
                <>
                    {exportsIds && exportsIds.length ?
                        exportsIds.map((exportId, index) => (
                            <GoogleSlidesExportItem key={exportId} exportId={exportId} />
                        ))
                        :
                        <div className="marginTop20">
                            No Exports.
                        </div>

                    }
                </>

            }
            <RemoteSlidesDialog
                onClose={() => setShowDialog(false)}
                open={showDialog}
                title="Export Google Slides File"
                actions={[
                    {label: 'Cancel', onClick: () => setShowDialog(false)},
                    {label: 'Export', onClick: onExport, disabled: !exportFileId.length}
                ]}>
                <div className="export-dialog-container">
                    <TextField
                        name="exportFileId"
                        label="Export File Id"
                        placeholder="1_dEsEEtmX6TMYrjMC2bPSPfYL8GTbwqv-gpDySZ5DiM"
                        value={exportFileId}
                        onChange={event => setExportFileId(event.target.value)}
                        fullWidth />
                </div>
            </RemoteSlidesDialog>
        </div>
    )
};

export default ExportsDemo;