import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import RemoteSlidesDialog from "../../_common/dialog";
import {deleteGoogleSlidesExport} from "../../../redux/exports/actions";
import {useExportsDetailsSelector} from "../../../redux/exports/selectors";
import {createSession} from "../../../redux/sessions/actions";

import './index.css';
import TextField from "@material-ui/core/TextField";

const GoogleSlidesExportItem = ({exportId}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [creatingSession, setCreatingSession] = useState(false);
    const [name, setName] = useState('');
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const googleSlidesExport = useExportsDetailsSelector(exportId);

    const onDelete = () => {
        setDeleting(true);
        dispatch(deleteGoogleSlidesExport(exportId, setDeleting, enqueueSnackbar));
    };

    const onCreateSession = () => {
        setShowDialog(false);
        setCreatingSession(true);
        dispatch(createSession(exportId, name, setCreatingSession, enqueueSnackbar));
    };

    return (
        <>
            <Card className="marginTop20">
                <CardContent>
                    <div>
                        <div className="exports-item-name">
                            {googleSlidesExport.name}
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    <div className="flex">
                        <div>
                            {deleting ?
                                <CircularProgress size={25} />
                                :
                                <Button variant="text" color="secondary" onClick={onDelete}>
                                    <DeleteIcon className="marginRight5" /> Delete
                                </Button>
                            }
                        </div>
                        <div>
                            {creatingSession ?
                                <CircularProgress size={25} />
                                :
                                <Button variant="text" color="secondary" onClick={() => setShowDialog(true)}>
                                    <AddIcon className="marginRight5" /> New Session
                                </Button>
                            }
                        </div>
                    </div>
                </CardActions>

            </Card>
            <RemoteSlidesDialog
                onClose={() => setShowDialog(false)}
                open={showDialog}
                title="Create Remote Slides Session"
                actions={[
                    {label: 'Cancel', onClick: () => setShowDialog(false)},
                    {label: 'Create', onClick: onCreateSession}
                ]}>
                <div className="export-dialog-container">
                    <TextField
                        name="name"
                        label="Session Name"
                        placeholder="Breakout Room 1"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        fullWidth />
                </div>
            </RemoteSlidesDialog>
        </>
    )
};

export default GoogleSlidesExportItem;