import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Helmet} from "react-helmet";

import {watchSession} from "../../redux/sessions/actions";
import {useSessionsSelector} from "../../redux/sessions/selectors";

import './index.css';

const WatchDemo = () => {
    const {sessionId} = useParams();
    const dispatch = useDispatch();
    const {watchingSlide, watchingSessionName} = useSessionsSelector();

    useEffect(() => {
        let cancel = null;
        dispatch(watchSession(sessionId, onCancel => cancel = onCancel));
        return () => {
            // Clean up the watcher
            if (cancel) {
                cancel();
            }
        }
    }, [dispatch, sessionId]);

    return (
        <div className="flex justify-content-center">
            {watchingSessionName &&
                <Helmet>
                    <title>{watchingSessionName}</title>
                </Helmet>
            }
            {!watchingSlide ?
                <CircularProgress size={45} />
                :
                <img src={watchingSlide} className="watch-demo-slide" alt="slide preview" />
            }
        </div>
    )

};

export default WatchDemo;