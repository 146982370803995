import {
    SESSIONS_LOADING,
    SESSIONS_SUCCESS,
    SESSIONS_ERROR,
    SESSION_CREATING,
    SESSION_SUCCESS,
    SESSION_ERROR,
    SESSION_DELETED,
    SESSION_WATCH_UPDATED
} from "./types";

/**
 * Loads all the RemoteSlidesSession objects currently in Firestore
 * @param enqueueSnackbar   notistack helper
 */
export const loadSessions = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: SESSIONS_LOADING });
        return api.getAllRemoteSlidesSessions()
            .then(sessions => {
                dispatch({ type: SESSIONS_SUCCESS, payload: sessions });
            })
            .catch(err => {
                dispatch({ type: SESSIONS_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Adds a new RemoteSlidesSession object in Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const createSession = (fileId, name, setCreatingSession, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: SESSION_CREATING });
        return api.createRemoteSlidesSessionForExport(fileId, name)
            .then(session => {
                dispatch({ type: SESSION_SUCCESS, payload: session });
                enqueueSnackbar("Session Created!", {variant: 'success'});
                if (setCreatingSession) {
                    setCreatingSession(false);
                }
            })
            .catch(err => {
                dispatch({ type: SESSION_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
                if (setCreatingSession) {
                    setCreatingSession(false);
                }
            });
    }
);

/**
 * Deletes a RemoteSlidesSession object in Firestore
 * @param sessionId The id of the RemoteSlidesSession object to delete
 * @param setDeleting   Sets the 'deleting' state of the corresponding SessionItem View
 * @param enqueueSnackbar   notistack helper
 */
export const deleteSession = (sessionId, setDeleting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.deleteRemoteSlidesSession(sessionId)
            .then(() => {
                dispatch({ type: SESSION_DELETED, payload: sessionId });
                enqueueSnackbar("Session Deleted", {variant: 'success'});
            })
            .catch(err => {
                if (setDeleting) {
                    setDeleting(false);
                }
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Sets the current slide number of a RemoteSlidesSession
 * @param sessionId The id of the RemoteSlidesSession object to update
 * @param slideNumber   The new slide number
 * @param enqueueSnackbar   Notistack helper
 */
export const setSessionSlideNumber = (sessionId, slideNumber, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.setRemoteSlidesSessionSlideNumber(sessionId, slideNumber)
            .then(session => {
                dispatch({ type: SESSION_SUCCESS, payload: session });
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Watches for real time updates of a RemoteSlidesSession
 * @param sessionId The id of the RemoteSlidesSession to watch
 * @param cancelCallback    Callback that receives the 'cancel' method from Firestore
 */
export const watchSession = (sessionId, cancelCallback) => (
    (dispatch, getState, { api }) => {
        const cancel = api.watchRemoteSlidesSession(sessionId, (slideUrl, currentSlide, sessionName) => {
            if (slideUrl) {
                //KBM - You could use currentSlide info here to bolt on the ability to capture User input between slides
                dispatch({ type: SESSION_WATCH_UPDATED, payload: {slideUrl, sessionName} });
            } else {
                window.close();
            }
        });
        cancelCallback(cancel);
    }
);