import {
    GOOGLE_SLIDES_EXPORTS_LOADING,
    GOOGLE_SLIDES_EXPORTS_SUCCESS,
    GOOGLE_SLIDES_EXPORTS_ERROR,
    GOOGLE_SLIDES_EXPORTING,
    GOOGLE_SLIDES_EXPORT_SUCCESS,
    GOOGLE_SLIDES_EXPORT_ERROR,
    GOOGLE_SLIDES_EXPORT_DELETED
} from "./types";

/**
 * Loads all the GoogleSlidesExports objects currently in Firestore
 * @param enqueueSnackbar   notistack helper
 */
export const loadGoogleSlidesExports = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: GOOGLE_SLIDES_EXPORTS_LOADING });
        return api.getAllGoogleSlidesExports()
            .then(exports => {
                dispatch({ type: GOOGLE_SLIDES_EXPORTS_SUCCESS, payload: exports });
            })
            .catch(err => {
                dispatch({ type: GOOGLE_SLIDES_EXPORTS_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Exports a Google Slides file in Google Drive to static images and uploads them Firebase Storage / Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const exportGoogleSlidesFile = (fileId, accessToken, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: GOOGLE_SLIDES_EXPORTING });
        return api.exportGoogleSlidesFile(fileId, accessToken)
            .then(googleSlidesExport => {
                dispatch({ type: GOOGLE_SLIDES_EXPORT_SUCCESS, payload: googleSlidesExport });
                enqueueSnackbar("Google Slides Exported!", {variant: 'success'});
            })
            .catch(err => {
                dispatch({ type: GOOGLE_SLIDES_EXPORT_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Exports a Google Slides file in Google Drive to static images and uploads them Firebase Storage / Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const exportPdf = (file, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: GOOGLE_SLIDES_EXPORTING });
        return api.exportPdf(file)
            .then(googleSlidesExport => {
                if (googleSlidesExport !== null) {
                    console.log("exportPdf", googleSlidesExport);
                    dispatch({ type: GOOGLE_SLIDES_EXPORT_SUCCESS, payload: googleSlidesExport });
                    enqueueSnackbar("Pdf Exported!", {variant: 'success'});
                }
            })
            .catch(err => {
                dispatch({ type: GOOGLE_SLIDES_EXPORT_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Deletes a GoogleSlidesExport object in Firestore
 * @param exportId The id of the GoogleSlidesExport object to delete
 * @param setDeleting   Sets the 'deleting' state of the corresponding SessionItem View
 * @param enqueueSnackbar   notistack helper
 */
export const deleteGoogleSlidesExport = (exportId, setDeleting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.deleteGoogleSlidesExport(exportId)
            .then(() => {
                dispatch({ type: GOOGLE_SLIDES_EXPORT_DELETED, payload: exportId });
                enqueueSnackbar("Export Deleted", {variant: 'success'});
            })
            .catch(err => {
                if (setDeleting) {
                    setDeleting(false);
                }
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);