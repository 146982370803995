import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import exports from './exports';
import sessions from './sessions';
import auth from './auth';
import login from './login';

const RootReducer = combineReducers({
    exports,
    sessions,
    auth,
    login
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;